import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, mergeAll, map, toArray } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SitesService {

  constructor(private http: HttpClient) { }

  getSilosData$(id): Observable<any> {
    return this.http.get<any[]>(`${environment.api2Url}/oye/impianto/${id}/sili/`).pipe(
        mergeAll(),
        map((x: any) => {
            const color = (x.stato_corrente.pesokg/x.capienza)*100;
            const silo = {
                id: x.id,
                color: '#38761d', // Commentata variazione colore in base al riempimento del silo::      color < 50 ? '#ff4500': '#38761d',
                name: x.nome,
                state: x.stato,
                capienza: x.capienza,
                timestamp: x.stato_corrente.timestamp_sessione,
                riempimento_relativo: x.stato_corrente.riempimento_relativo,
                pesokg: x.stato_corrente.pesokg,
                stato_silo: x.stato_corrente.stato_silo,
                descrizione_contenuto: x.stato_corrente.descrizione_contenuto,
                is_abilitazione: x.is_abilitazione,
                is_manutenzione: x.is_manutenzione,
                cla_sonda: x.stato_corrente.cla_sonda,
            }
            return silo;
        }),
        toArray()
    )
}
}
